<script>
import GetInvestorErrorNotification from '@/components/Notifications/Investors/GetInvestorErrorNotification';

import pessoasService from '@/services/Pessoas/pessoasService';

export default {
  name: 'register-disclaimer',
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
  },
  data() {
    return {
      operationalInformations: [],
      pessoa: {
        dados_complementares: {},
      },
    };
  },
  mounted() {
    this.getInvestorInformations();
  },
  methods: {
    async getInvestorInformations() {
      try {
        const user = await pessoasService.findCurrentUser();
        this.operationalInformations.push(user);

        this.pessoa = user;
      } catch (e) {
        console.error('get investor information error', error);
        this.$notify({
          component: GetInvestorErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- <div
      v-if="pessoa.dados_complementares.status === 'empty-register'"
      role="alert"
      class="alert alert-warning"
    >
      <span>
        Seu cadastro não está completo. Por favor verifique os dados que faltam na opção 
        <router-link to="/profile" tag="span" style="cursor: pointer !important;">
          <b>Meus Dados</b>.
        </router-link>
      </span>
    </div> -->

    <!-- <div
      v-if="pessoa.dados_complementares.status === 'completed-register' && pessoa.dados_complementares.approvalStatus !== 'Aprovado'"
      role="alert"
      class="alert alert-info"
    >
      <span>
        Seu cadastro já foi preenchido, e está sob analíse. Você será informado por e-mail de qualquer atualização.
      </span>
    </div> -->
  </div>
</template>
