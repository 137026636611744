import store from '@/store/index';

// GeneralViews
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import NotFound from '@/pages/NotFoundPage.vue';

const modalidade = process.env.VUE_APP_TYPE;

// Admin pages
const Dashboard = () => import('@/pages/Dashboard.vue');
const Profile = () => import('@/pages/Profile.vue');
const SignIn = () => import('@/pages/Authentication/SignIn.vue');
const SignUp = () => import('@/pages/Authentication/SignUp.vue');

const SocialAgent = () =>
  import('@/pages/SocialTransformation/SocialAgent.vue');
const EditSocialAgent = () =>
  import('@/pages/SocialTransformation/EditSocialAgent.vue');

const Users = () => import('@/pages/Operationals/Users.vue');
const AddUser = () => import('@/pages/Operationals/AddUser.vue');
const EditUser = () => import('@/pages/Operationals/EditUser.vue');

const Banks = () => import('@/pages/Banks/Banks.vue');
const AddBank = () => import('@/pages/Banks/AddBank.vue');
const EditBank = () => import('@/pages/Banks/EditBank.vue');

const Categorias = () => import('@/pages/Categorias/Categorias.vue');
const AddCategoria = () => import('@/pages/Categorias/AddCategoria.vue');
const EditCategoria = () => import('@/pages/Categorias/EditCategoria.vue');

const SegmentInterest = () => import('@/pages/SegmentInterest/SegmentInterest');
const EditSegment = () => import('@/pages/SegmentInterest/EditSegment');
const AddSegment = () => import('@/pages/SegmentInterest/AddSegment');

const States = () => import('@/pages/States/States');
const EditState = () => import('@/pages/States/EditState');
const AddState = () => import('@/pages/States/AddState');

const Financing = () => import('@/pages/Financing/Financing');
const AddFinancing = () => import('@/pages/Financing/AddFinancing');
const EditFinancing = () => import('@/pages/Financing/EditFinancing');
const ResumeFinancing = () => import('@/pages/Financing/ResumeFinancing');
const Contributions = () => import('@/pages/Financing/Contributions');

const Values = () => import('@/pages/Values/Values');
const AddValue = () => import('@/pages/Values/AddValue');
const EditValue = () => import('@/pages/Values/EditValue');

const InvestimentsProfile = () =>
  import('@/pages/InvestmentsProfile/InvestmentsProfile');
const AddInvestment = () => import('@/pages/InvestmentsProfile/AddInvestment');
const EditInvestment = () =>
  import('@/pages/InvestmentsProfile/EditInvestment');

const Attachments = () => import('@/pages/Attachments/AttachmentsList');
const AddAtachment = () => import('@/pages/Attachments/AddAttachment');

const TermsOfUseList = () => import('@/pages/TermsOfUse/TermsOfUseList');
const AddTermOfUse = () => import('@/pages/TermsOfUse/AddTermOfUse');
const TermOfUse = () => import('@/pages/TermsOfUse/TermOfUse');

const InvestorsList = () => import('@/pages/Investors/InvestorsList');
const InvestorDetail = () => import('@/pages/Investors/InvestorDetail');

const CampaignDetails = () => import('@/pages/Campaigns/CampaignDetails');

const Logs = () => import('@/pages/Logs/Logs');

const DocumentsList = () => import('@/pages/Documents/DocumentsList');
const AttachIdentificationDocument = () =>
  import('@/pages/Documents/AttachIdentificationDocument');
const AttachIncomeDocument = () =>
  import('@/pages/Documents/AttachIncomeDocument');
const AttachAddressDocument = () =>
  import('@/pages/Documents/AttachAddressDocument');
const AttachOtherDocument = () =>
  import('@/pages/Documents/AttachOtherDocument');

const ReportsList = () => import('@/pages/Reports/ReportsList');
const AnnualReport = () => import('@/pages/Reports/AnnualReport');
const PaymentReport = () => import('@/pages/Reports/PaymentReport');
const OffersReport = () => import('@/pages/Reports/OffersReport');
const CampaignGeneral = () => import('@/pages/Reports/CampaignsGeneral');
const CampaignStatement = () => import('@/pages/Reports/CampaignStatement');

const DynamicFields = () => import('@/pages/DynamicFields/DynamicFields');
const AddDynamicField = () => import('@/pages/DynamicFields/AddDynamicField');
const EditDynamicField = () => import('@/pages/DynamicFields/EditDynamicField');
const Settings = () => import('@/pages/Settings/Settings');
const Transactions = () => import('@/pages/Transactions/Transactions');

const ForgotPassword = () => import('@/pages/Authentication/ForgotPassword');

//Social Transformation
const RegisterSocialTransformationAgent = () =>
  import('@/pages/SocialTransformation/RegisterSocialTransformationAgent');

const beforeEnter = (to, from, next) => {
  const Claim = store.getters.userClaim.role;

  if (
    Claim !== 'master' &&
    Claim !== 'gestor' &&
    to.name !== 'Criar Campanha' &&
    to.name !== 'Criar Oferta' &&
    to.name !== 'Ver Campanha' &&
    to.name !== 'Ver Oferta' &&
    to.name !== 'Campanhas' &&
    to.name !== 'Ofertas' &&
    to.name !== 'Ver Campanha '
  ) {
    next('dashboard');
  }

  next();
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/login/:ref',
        component: SignIn,
        name: 'login-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/login',
        component: SignIn,
        name: 'login',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register/:ref',
        component: SignUp,
        name: 'register-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register',
        component: SignUp,
        name: 'register',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/term-of-use',
        name: 'term-of-use',
        component: TermOfUse,
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/dynamic-fields',
        component: DynamicFields,
        name: 'Campos Dinâmicos',
      },
      {
        path: '/add-dynamic-field',
        component: AddDynamicField,
        name: 'Criar Campo Dinâmico',
      },
      {
        path: '/edit-dynamic-field/:id',
        component: EditDynamicField,
        name: 'Editar Campo Dinâmico',
      },
      {
        path: '/settings',
        component: Settings,
        name: 'Configurações da plataforma',
      },
      {
        path: '/transactions/:type/:id',
        component: Transactions,
        name: 'Transações',
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        // beforeEnter,
      },
      {
        path: 'profile',
        name: 'Meus Dados',
        component: Profile,
      },
      {
        path: 'users',
        name: 'Usuários',
        component: Users,
        beforeEnter,
      },
      {
        path: 'social-transformation/agents',
        name: 'Agentes (API3)',
        component: SocialAgent,
      },
      {
        path: 'social-transformation/agents/:id',
        name: 'Editar Agente (API3)',
        component: EditSocialAgent,
      },
      {
        path: 'add-user',
        name: 'Adicionar Usuário',
        component: AddUser,
        beforeEnter,
      },
      {
        path: 'edit-user/:id',
        name: 'Editar Usuário',
        component: EditUser,
        beforeEnter,
      },
      {
        path: 'banks',
        name: 'Bancos',
        component: Banks,
        beforeEnter,
      },
      {
        path: 'categorias',
        name: 'Categorias',
        component: Categorias,
        beforeEnter,
      },
      {
        path: 'add-categoria',
        name: 'Adicionar Categoria',
        component: AddCategoria,
        beforeEnter,
      },
      {
        path: 'edit-categoria/:id',
        name: 'Editar Categoria',
        component: EditCategoria,
        beforeEnter,
      },
      {
        path: 'add-bank',
        name: 'Adicionar Banco',
        component: AddBank,
        beforeEnter,
      },
      {
        path: 'edit-bank/:id',
        name: 'Editar Banco',
        component: EditBank,
        beforeEnter,
      },
      {
        path: 'segment-interest',
        name: 'Segmentos',
        component: SegmentInterest,
        beforeEnter,
      },
      {
        path: 'add-segment',
        name: 'Adicionar Segmento',
        component: AddSegment,
        beforeEnter,
      },
      {
        path: 'edit-segment/:id',
        name: 'Editar Segmento',
        component: EditSegment,
        beforeEnter,
      },
      {
        path: 'states',
        name: 'Estados',
        component: States,
        beforeEnter,
      },
      {
        path: 'add-state',
        name: 'Adicionar Estado',
        component: AddState,
        beforeEnter,
      },
      {
        path: 'edit-state/:id',
        name: 'Editar Estado',
        component: EditState,
        beforeEnter,
      },
      {
        path: 'values',
        name: 'Valores',
        component: Values,
        beforeEnter,
      },
      {
        path: 'add-values',
        name: 'Adicionar Valores',
        component: AddValue,
        beforeEnter,
      },
      {
        path: 'edit-values/:id',
        name: 'Editar Valores',
        component: EditValue,
        beforeEnter,
      },
      {
        path: 'investments-profile',
        name: 'Perfil de Investimentos',
        component: InvestimentsProfile,
        beforeEnter,
      },
      {
        path: 'add-investment',
        name: 'Adicionar Investimento',
        component: AddInvestment,
        beforeEnter,
      },
      {
        path: 'edit-investment/:id',
        name: 'Editar Investimento',
        component: EditInvestment,
        beforeEnter,
      },
      {
        path: 'financing',
        name: modalidade == 'doacao' ? 'Campanhas' : 'Ofertas',
        component: Financing,
        beforeEnter,
      },
      {
        path: 'add-financing',
        name: modalidade == 'doacao' ? 'Criar Campanha' : 'Criar Oferta',
        component: AddFinancing,
        beforeEnter,
      },
      {
        path: 'resume-financing/:id',
        name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
        component: ResumeFinancing,
        beforeEnter,
      },
      {
        path: 'edit-financing/:id',
        name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
        component: EditFinancing,
        beforeEnter,
      },
      {
        path: 'contributions/:id',
        name: 'Ver Aportes',
        component: Contributions,
      },
      {
        path: 'attachments',
        name: 'Anexos',
        component: Attachments,
        beforeEnter,
      },
      {
        path: 'add-attachment',
        name: 'Adicionar Anexo',
        component: AddAtachment,
        beforeEnter,
      },
      {
        path: 'investors',
        name: 'Investidores',
        component: InvestorsList,
        beforeEnter,
      },
      {
        path: 'investor-detail/:id',
        name: 'Detalhes Investidor',
        component: InvestorDetail,
        beforeEnter,
      },
      {
        path: 'terms-of-use',
        name: 'Contratos',
        component: TermsOfUseList,
        beforeEnter,
      },
      {
        path: 'add-term-of-use',
        name: 'Adicionar Contrato',
        component: AddTermOfUse,
        beforeEnter,
      },
      {
        path: 'campaign-details/:id',
        name:
          modalidade == 'doacao'
            ? 'Detalhes da Campanha'
            : 'Detalhes da Oferta',
        component: CampaignDetails,
        // beforeEnter,
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs,
        beforeEnter,
      },
      {
        path: '/social-transformation/register',
        name: 'Agente de Pertencimento ICoOwner ODS/ESG',
        component: RegisterSocialTransformationAgent,
      },
      {
        path: 'documents-list',
        component: DocumentsList,
        children: [
          {
            path: '',
            name: 'Identificação',
            component: AttachIdentificationDocument,
          },
          {
            path: 'income-document',
            name: 'Renda',
            component: AttachIncomeDocument,
          },
          {
            path: 'address-document',
            name: 'Endereço',
            component: AttachAddressDocument,
          },
          {
            path: 'other-document',
            name: 'Outro',
            component: AttachOtherDocument,
          },
        ],
      },
      {
        path: 'reports',
        name: '',
        component: ReportsList,
        beforeEnter,
        children: [
          {
            path: 'annual-report',
            name: 'Relatório Anual',
            component: AnnualReport,
          },
          {
            path: 'payment',
            name: 'Relatório Pagamento',
            component: PaymentReport,
          },
          {
            path: 'offers',
            name: 'Relatório Ofertas',
            component: OffersReport,
          },
          {
            path: 'campaigns-general',
            name: 'Relatório Geral',
            component: CampaignGeneral,
          },
          {
            path: 'campaign-statement/:id',
            name:
              modalidade == 'doacao'
                ? 'Relatório Campanha'
                : 'Relatório Oferta',
            component: CampaignStatement,
          },
        ],
      },
    ],
  },
  { path: '*', component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
